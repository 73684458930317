/* App.css */
:root {
  --color-primary: #28a490;
  --color-secondary: #6c757d;
  --color-success: #28a745;
  --color-danger: #dc3545;
  --color-warning: #ffc107;
  --color-info: #17a2b8;
  --color-light: #f8f9fa;
  --color-dark: #343a40;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.85rem;
}

.App {
  text-align: center;
  background-color: var(--color-light);
  min-height: 100vh;
}

.auth-container {
  background-color: var(--color-light);
  padding: 20px;
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
}

input {
  border: 1px solid #ccc;
  font-size: 0.85rem;
  padding: 8px;
  border-radius: 4px;
}

/* Button Styles */
.btn {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 0.9rem;
  border: none;
  cursor: pointer;
  transition: opacity 0.2s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

.btn:hover {
  opacity: 0.9;
}

.btn-primary {
  background-color: var(--color-primary);
  color: white;
}

.btn-secondary {
  background-color: var(--color-secondary);
  color: white;
}

.btn-danger {
  background-color: var(--color-danger);
  color: white;
}

.btn-success {
  background-color: var(--color-success);
  color: white;
}

/* Utility Classes */
.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.btn:disabled {
  cursor: not-allowed;
}
