@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* This makes the html font-size 75% of the default (16px -> 12px). */
  html {
    font-size: 75%;
  }

  /* Remove scrollbars from the entire application */
  html,
  body,
  div {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }

  /* WebKit browsers (Chrome, Safari) */
  html::-webkit-scrollbar,
  body::-webkit-scrollbar,
  div::-webkit-scrollbar {
    display: none;
  }
}
